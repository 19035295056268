<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 头部注释
 * @Date: 2021-01-04 11:17:38
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-01-06 16:07:53
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/order/finance.vue
-->
<template>
  <div class="user_content">
    <van-tabs v-model="type" @click="tabkey">
      <van-tab v-for="(item,index) in tablist" :key="index" :title="item.name" />
    </van-tabs>
    <p class="tip">*声明：本订单信息仅代表用户发起过申请，不代表完成了申请流程</p>
    <mescroll :up="mescrollUp" :down="mescrollDown">
      <div slot="scrollList">
        <div v-if="!list.length">
          <van-empty description="暂无数据" />
        </div>
        <div v-else class="orderlist">
          <ul>
            <li v-for="(item,index) in list" :key="index">
              <div class="sptitle">
                <div>工商银行</div>
                <div>交易订单：<span id="ordernumber">20202201030221C01</span> <span class="cop" @click="copy">复制</span>
                </div>
              </div>
              <div class="sp_name">
                <span>客户姓名：李**（ID：94567）</span><span>无需查询，自动结算</span>
              </div>
              <div class="sp">手机号码：134 **** 3356</div>
              <div class="sp">申请时间：2020-11-05 22:34</div>
              <div class="sp">订单状态：<span style="color:#E33F44;">已下单</span></div>
            </li>
          </ul>
        </div>
      </div>
    </mescroll>
  </div>
</template>

<script>
import Vue from 'vue'
import { Empty, Tab, Tabs, Toast } from 'vant'

Vue.use(Tab).use(Tabs).use(Empty).use(Toast)
import {
  liveOrderList
} from '@/services/userApi'
import mescroll from '@/component/fresh_scroll/mescroll'
import { copyText } from '@/utils/common/utils'

export default {
  components: {
    mescroll
  },
  data() {
    return {
      nolist: false,
      type: 0,
      page: 1,
      pageSize: 10,
      list: [],
      tablist: [
        { name: '全部' },
        { name: '待结算' },
        { name: '已结算' },
        { name: '已完成' }
      ],
      mescrollDown: {
        use: false,
        bottom: '0',
        top: '87px'
      },
      mescrollUp: {
        initCallBack: this.mescrollInit,
        callback: this.upCallback, // 上拉回调,此处可简写; 相当于 callback: function (page, mescroll) { getListData(page); }
        page: {
          num: 0, // 当前页码,默认0,回调之前会加1,即callback(page)会从1开始
          size: 10 // 每页数据的数量
        },
        noMoreSize: 5, // 如果列表已无数据,可设置列表的总数量要大于等于5条才显示无更多数据;避免列表数据过少(比如只有一条数据),显示无更多数据会不好看
        lazyLoad: {
          use: true
        }
      }
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    getinfo() {
      const prom = {
        type: this.type,
        page: this.page,
        pageSize: this.pageSize
      }
      liveOrderList(prom).then(res => {
        if (Number(res.code) === 200) {
          this.list = res.list
        }
      })
    },
    mescrollInit(data) {
      this.mescroll = data
    },
    tabkey() {
      this.getinfo()
    },
    copy() {
      const ordernumber = document.getElementById('ordernumber').innerText

      copyText(ordernumber, () => {
        Toast('复制成功')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user_content {
  width: 100%;
  height: auto;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f6f6f6;
  padding-bottom: 80px;

  .tip {
    color: #999999;
    padding: 13px 13px 0;
    font-size: 10px;
    text-align: left;
    line-height: 20px;
  }

  .orderlist {
    & ul li {
      background: #FFFFFF;
      margin: 13px;
      padding: 10px;
      border-radius: 4px;

      .sptitle {
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > div:nth-child(1) {
          color: #333333;
          font-size: 15px;
          font-weight: 500;
        }

        & > div:nth-child(2) {
          color: #666666;
          font-size: 10px;
        }

        .cop {
          border: 1px solid #000;
          margin-left: 15px;
          padding: 3px 7px;
          border-radius: 20px;
        }
      }

      .sp_name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        & > span:nth-child(1) {
          color: #333333;
          font-size: 13px;
        }

        & > span:nth-child(2) {
          color: #E33F44;
          font-size: 12px;
        }
      }

      .sp {
        margin-top: 10px;
        font-size: 13px;
        color: #333333;
        text-align: left;
      }
    }
  }
}
</style>
